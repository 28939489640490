import { Link } from "@StarberryUtils"
import React from "react";
import { Row, Col } from 'react-bootstrap';
import { VALUATION_PAGE_UEL } from "@Components/common/site/constants";
import './GetStartCard.scss';
import ScrollAnimation from 'react-animate-on-scroll';

const GetStartCard = (props) => {
  return (
    <div className="get-start-section">
      <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
        {/* <Container> */}
          <div className="get-start-row">
            <Row className="align-items-md-center">
              <Col md="7">
                <h2>Are you looking to Sell or Let your property?</h2>
                <p>Arrange a free valuation appointment</p>
              </Col>
              <Col md="5" className="text-center text-md-right">
                <Link to={VALUATION_PAGE_UEL.alias} className="btn btn-primary dark-bg">Get Started</Link>
              </Col>
            </Row>
          </div>
        {/* </Container> */}
      </ScrollAnimation>
    </div>
  )
}

export default GetStartCard;

