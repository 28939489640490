import React from "react"
import {  Container, Row, Col } from 'react-bootstrap';
import BreadcrumbsTop from '../SearchResult/BreadcrumbsTop/BreadcrumbsTop'
import FilterOption from '../SearchResult/filterOption/filterOption'
import PropertyCard from '../SearchResult/PropertyCard/PropertyCard'
import GetStartCard from '../SearchResult/GetStartCard/GetStartCard'
import SubscribeBlock from '../SearchResult/SubscribeBlock/SubscribeBlock'
import BreadcrumbsBottom from '../SearchResult/BreadcrumbsBottom/BreadcrumbsHome'
// images
import mapImg from "../../images/search-result/map.jpg"
import propertycartImg from "../../images/search-result/propertycart.jpg"
import './SearchResult.scss';

const SearchResult = (props) => {

  return (
    <div className="">
      <div className="result-section">
        <div className="list-view-wrap">

          <BreadcrumbsTop />
          <Container>
            <Row>
              <Col>
                <div className="search-heading">
                  {/* <ScrollAnimation animateIn='fadeIn' animateOnce={true}> */}
                  <h1><span className="d-md-none">36</span> Properties for sale in Manchester</h1>
                  {/* </ScrollAnimation> */}
                </div>
              </Col>
            </Row>
          </Container>
          <FilterOption />
          <Container>
            <Row>
              <Col>
                <div className="property-card-list">
                  <PropertyCard />
                </div>
              </Col>
            </Row>
          </Container>
          <GetStartCard />

        </div>
        <div className="map-view-wrap">
          <img src={mapImg} alt="" className="map_img" />
          <div className="propertycart">
            <div className="propertycart_img">
              <img src={propertycartImg} alt="" />
            </div>
            <div className="propertycart-info">
              <strong>£ 520,676</strong>
              <h2>3 bedroom apartment for sale</h2>
              <p>Victoria residence, Crown Street, Manchester, M15</p>
            </div>
          </div>

        </div>

      </div>
      <SubscribeBlock />
      <BreadcrumbsBottom />
    </div>
  )
}



export default SearchResult