import React from "react";
import { Link } from "@StarberryUtils"
// images
import mainImg01Large from "../../../images/search-result/property-img1-large.jpg"
import mainImg01Small from "../../../images/search-result/property-img1-small.jpg"
import mainImg02Large from "../../../images/search-result/property-img2-large.jpg"
import mainImg02Small from "../../../images/search-result/property-img2-small.jpg"

import ScrollAnimation from 'react-animate-on-scroll';
import  "./PropertyCard.scss";

  const PropertyCard = (props) => {

    const propOptions = [
        { price: "652,117", 
        properyTitle: "3 bedroom apartment for sale", 
        properyLocation: "King Street, Manchester, M2",
        properyInfo: "The King Street collection is a conversion developement centrally located in the heart of Manchester city centre with an array of shops, restaurants and bars at your doorstep. Three Storey townhouses with private roof terraces, offering stylish and modern...",
        properyDistance: "Piccadily Station (0.6 miles)",
        mainImgLarge: mainImg01Large,
        mainImgSmall: mainImg01Small
        },
        { price: "520,676", 
        properyTitle: "3 bedroom apartment for sale", 
        properyLocation: "Victoria residence, Manchester, M15",
        properyInfo: "Victoria Residence is located on Crown Street and within walking distance to the City centre. These three bedroom apartments are finished to a high standard and comprise of an open plan living space with fully fitted kitchens, three double bedrooms...",
        properyDistance: "Piccadily Station (0.6 miles)",
        mainImgLarge: mainImg02Large,
        mainImgSmall: mainImg02Small
        },
  
    ];

    const addingClass = (e) =>{
        // let lexicalE = e.persist();
                let dm = document.getElementsByClassName("active-map")
                // console.log("testing",dm)
                if(dm.length ===0 ) {
                 if(document.querySelector('.active-map')!==null){
                    document.querySelector('.active-map').classList.remove('active-map')
                    e.target.classList.add("active-map")                    
            }
                    e.target.classList.add("active-map")
                    
                } else {

               document.querySelector('.active-map').classList.remove('active-map')
        }
            // console.log("test",e.target.className ,dm)
    }

   
    return(<React.Fragment>
        {propOptions.map((item, index) =><div className="property-card d-md-flex flex-wrap"  key={index}>
            <div className="view-part1">
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className="main-item">
                <div className="img-zoom">
                <picture>
                <source media="(min-width:768px)" srcset={item.mainImgLarge} />
                <img className="propery-img" src={item.mainImgSmall} alt="{item.properyTitle}" />
                </picture>
                </div>
                        {/* <img src={item.mainImg} alt="Image" /> */}
                        <div className="on-map d-flex">
                            <Link to="#" onClick={e=>addingClass(e)}><i className="icon-camera"></i><span> 14</span></Link>
                            <Link to="#"><i className="icon-play-btn"></i> <span> 1</span></Link>
                        </div>
                    </div>
                   
              </ScrollAnimation>
            </div>
        
            <div className="card-details">
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                <div className="detail-head d-flex align-items-center justify-content-between">
                    <h2 className="price">&pound;{item.price}</h2>
                    <Link className="wishlist-icn" to="#"><i className="icon-heart"></i></Link>
                </div> 
                <h2 className="prop-title d-block"><Link href="#">{item.properyTitle}</Link></h2>
                <span className="address-title d-block">{item.properyLocation}</span>
                <p className="prop-desc">{item.properyInfo}</p>
                <div className="d-lg-flex justify-content-between">
                    <span className="distance d-flex align-items-center"><i className="icon-station"></i>{item.properyDistance}</span>
                    <span className="distance d-flex align-items-center"><i className="icon-date"></i> Book a viewing</span>
                </div>
                </ScrollAnimation>
            </div>
        </div>)}

        </React.Fragment>)
  }
export default PropertyCard
