import React from "react";
import {Row, Col, Container,Breadcrumb } from 'react-bootstrap';
const BreadcrumbsBottom =(props) => {

    return(
  <div className="breadcrumb-block text-center" >
    <Container>
        <Row> 
        <Col lg={12}>
        
            <Breadcrumb>
                <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Services</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Buy</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Property for sale</Breadcrumb.Item>
                <Breadcrumb.Item active>King Street, Manchester, M2</Breadcrumb.Item>
               
            </Breadcrumb>
            
        </Col>
        </Row>
    </Container>
  </div>
)
    }

export default BreadcrumbsBottom;

