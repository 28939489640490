import * as React from "react"
import Header from "@Components/Header/Header";
import Footer from "@Components/Footer/Footer";
import SEO from "@Components/seo";
import SearchResultComponents from "@Components/SearchResult/SearchResult"
// import  './SearchResult.scss';

const SearchResult = ( props ) => {

  const [state,setState] = React.useState({
    showMenu:false
  })

  const handlerClick = () => {
    // console.log('okkk')
    setState({...state,showMenu:!state.showMenu})
  }

  return(

    <div className={state.showMenu ? "wrapper header-style open-search-block" : "wrapper header-style"}>
    <Header showMenu={state.showMenu} handlerClick={handlerClick}/>
    <SEO/>
    <SearchResultComponents/> 
    <Footer/>
    </div>
         
  
)
  }



export default SearchResult