import { Link } from "@StarberryUtils"
import React, { useEffect, useState } from "react";
import { Button, Form, Container, Row, Col, Fade } from 'react-bootstrap';
import './FilterOption.scss';

const FilterOption = (props) => {

    const [open, setOpen] = useState(false);

    const showDropdown = () => {
        setOpen(!open)
    }

    const outSideLink = (e) => {
        // console.log("avvaa", e.target.classList)
        if (!e.target.className.includes("custom-control-label") && !e.target.className.includes('custom-control-input')
            && !e.target.className.includes('fade') && !e.target.className.includes('dropdown-exp')
            && !e.target.className.includes('offer-list') && !e.target.className.includes('mb-3')
            && !e.target.className.includes('btn')) {
            setOpen(open)
        }
    }

    useEffect(() => {
        document.addEventListener('click', outSideLink)
    }, [])


    return (
        <Container className="">
            {/* <ScrollAnimation animateIn='fadeIn' animateOnce={true}> */}
            <Row>
                <Col>
                    <div className="filter-block">
                        <Link href="#" className="filter-property-btn btn btn-outline">Filter Properties</Link>
                        <div className="filter-list">
                            <Form>
                                <ul>
                                    <li>
                                        <Form.Group>
                                            <Form.Control type="text" className="postcode_input" placeholder="Street, area or postcode" />
                                        </Form.Group>
                                    </li>
                                    <li>
                                        <Form.Group>
                                            <Form.Control type="text" placeholder="Radius" />
                                        </Form.Group>
                                    </li>
                                    <li>
                                        <Form.Group>
                                            <div className="select-box">
                                                <select className="form-control">
                                                    <option>Min Price</option>
                                                    <option>Option1</option>
                                                    <option>Option2</option>
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </li>
                                    <li>
                                        <Form.Group>
                                            <div className="select-box">
                                                <select className="form-control">
                                                    <option>Max Price</option>
                                                    <option>Option1</option>
                                                    <option>Option2</option>
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </li>
                                    <li>
                                        <Form.Group>
                                            <div className="select-box">
                                                <select className="form-control">
                                                    <option>Beds</option>
                                                    <option>Option1</option>
                                                    <option>Option2</option>
                                                </select>
                                            </div>
                                        </Form.Group>
                                    </li>
                                    <li className="dropdown_box">
                                        <Button
                                            variant="link"
                                            onClick={e => showDropdown()}
                                            aria-controls="example-fade-text"
                                            aria-expanded={open}>
                                            More
                    </Button>
                                        {
                                            open ?
                                                <Fade in={open}>
                                                    <div className="dropdown-exp" onClick="showDropdownss()">

                                                        <div className="mb-3">
                                                            <h4>Property Type</h4>
                                                            <ul className="offer-list d-flex flex-wrap">
                                                                <li>
                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label="Detached"
                                                                        type="checkbox"
                                                                        id="checkbox-1"
                                                                    />
                                                                </li>
                                                                <li>
                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label="Semi-Detached"
                                                                        type="checkbox"
                                                                        id="checkbox-2"
                                                                    />
                                                                </li>
                                                                <li>
                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label="Terraced"
                                                                        type="checkbox"
                                                                        id="checkbox-3"
                                                                    />
                                                                </li>
                                                                <li>
                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label="Apartment"
                                                                        type="checkbox"
                                                                        id="checkbox-4"
                                                                    />
                                                                </li>
                                                                <li>
                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label="Flat"
                                                                        type="checkbox"
                                                                        id="checkbox-5"
                                                                    />
                                                                </li>
                                                                <li>
                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label="Bungalow"
                                                                        type="checkbox"
                                                                        id="checkbox-6"
                                                                    />
                                                                </li>
                                                            </ul>
                                                            <h4>Must Haves</h4>
                                                            <ul className="offer-list d-flex flex-wrap">
                                                                <li>
                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label="Garden"
                                                                        type="checkbox"
                                                                        id="checkbox-7"
                                                                    />
                                                                </li>
                                                                <li>
                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label="Garage"
                                                                        type="checkbox"
                                                                        id="checkbox-8"
                                                                    />
                                                                </li>
                                                                <li>
                                                                    <Form.Check
                                                                        custom
                                                                        inline
                                                                        label="Parking"
                                                                        type="checkbox"
                                                                        id="checkbox-9"
                                                                    />
                                                                </li>

                                                            </ul>
                                                        </div>

                                                    </div>
                                                </Fade>
                                                :
                                                !open
                                        }
                                    </li>
                                </ul>
                                <div className="filter-input">
                                    <ul>
                                        <li>
                                            {/* <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Include Sold / SSTC" />
                </Form.Group> */}
                                            <Form.Check
                                                custom
                                                inline
                                                label="Include Sold / SSTC"
                                                type="checkbox"
                                                id="checkbox-12"
                                            />
                                        </li>
                                        <li>
                                            Sort by: <span>Most recent</span>
                                        </li>
                                        <li>
                                            <strong>Get alerts for new properties</strong>
                                        </li>
                                    </ul>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
            </Row>
            {/* </ScrollAnimation> */}
        </Container>
    )
}

export default FilterOption;

